<template>
    <div class="MySelectPicker">
        <van-field :value="select"
                   clickable
                   readonly
                   v-bind="$attrs"
                   @click="onShowPicker"
        >
            <van-icon v-if="!$attrs.disabled && $attrs.clearable && select"
                      slot="right-icon"
                      name="clear"
                      @click="onClear"
            />
        </van-field>
        <my-drawer v-model="showPicker"
                   :fullscreen="false"
                   :title="$attrs.placeholder"
                   :with-nav="false"
                   position="bottom"
                   round
                   @open="onOpen"
        >
            <!-- 多选 -->
            <div v-if="multiple" class="MySelectPicker__multiple">
                <div v-for="(v, index) in options"
                     :key="index"
                     :class="['MySelectPicker__multipleOption', {active: selection.indexOf(v.value) !== -1}]"
                     @click="onSelect(v)"
                >
                    <div class="MySelectPicker__multipleOption-text">{{ v.text }}</div>
                    <van-icon v-if="selection.indexOf(v.value) !== -1"
                              class="MySelectPicker__multipleOption-icon"
                              name="success"
                    />
                </div>
            </div>
            <!-- 单选 -->
            <van-picker v-else
                        :columns="options"
                        :default-index="defaultIndex"
                        show-toolbar
                        @cancel="onClosePicker"
                        @confirm="onConfirm"
            />
            <template v-if="multiple" #footer>
                <van-button block native-type="button" size="small" type="default" @click="onClosePicker">取消</van-button>
                <van-button block native-type="button" size="small" type="info" @click="onMultiSelect">确定</van-button>
            </template>
        </my-drawer>
    </div>
</template>

<script>
    import MyDrawer from "../drawer";

    export default {
        name: "MySelectPicker",
        inheritAttrs: false,
        components: {MyDrawer},
        props: {
            options: Array,
            value: [String, Number, Array],
            multiple: {
                type: Boolean,
                default: false
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                showPicker: false,
                select: '',
                selection: []
            }
        },
        computed: {
            // 候选项
            // fieldOptions() {
            //     return this.item.fieldDataList;
            // },
            // picker组件默认选中
            defaultIndex() {
                return this.options.findIndex(v => {
                    if (this.value) {
                        return v.value === this.value;
                    } else {
                        return 0;
                    }
                });
            }
        },
        watch: {
            value: {
                handler: 'setDefault'
            }
        },
        mounted() {
            this.setDefault();
        },
        methods: {
            onClear() {
                this.select = '';
                let value = this.multiple ? [] : '';
                this.$emit('input', value);
            },
            onOpen() {
                if (this.multiple && this.value) {
                    let items = this.options.filter(v => this.value.includes(v.value));
                    this.selection = items.map(e => e.value);
                }
            },
            // 下拉多选，选择事件
            onSelect(item) {
                let {value} = item;
                let index = this.selection.indexOf(value);
                // 如果已存在，反选
                if (index !== -1) {
                    this.selection.splice(index, 1);
                } else {
                    // 追加
                    this.selection.push(value);
                }
            },
            // 展示选择器
            onShowPicker() {
                // 禁用状态不展开
                if (this.$attrs.disabled) return;
                this.showPicker = true;
            },
            // 隐藏选择器
            onClosePicker() {
                this.showPicker = false;
                this.selection = [];
            },
            // 默认值
            setDefault() {
                // value不为空
                if (this.value) {
                    // 多选
                    if (this.multiple) {
                        let multiples = this.options.filter(e => this.value.includes(e.value));
                        this.select = multiples.map(item => item.text).join(',');
                    } else {
                        // 单选
                        let {text = ''} = this.options.find(v => v.value === String(this.value)) || {};
                        this.select = text;
                    }
                } else {
                    this.select = '';
                    if (this.multiple && ['string', 'number'].includes(typeof this.value)) {
                        this.$emit('input', []);
                    }
                }
            },
            // 下拉多选确认操作
            onMultiSelect() {
                if (this.selection.length > 0) {
                    let select = [];
                    let inputValue = [];
                    // 匹配已选的候选项
                    let items = this.options.filter(v => this.selection.includes(v.value));
                    if (items.length > 0) {
                        items.forEach(item => {
                            let {text, value} = item;
                            select.push(text);
                            inputValue.push(value);
                        });
                    }
                    this.select = select.join(',');
                    this.$emit('input', inputValue);
                }
                this.onClosePicker();
            },
            // picker组件确认操作
            onConfirm(item) {
                let {text, value} = item;
                this.select = text;
                console.log('confirm', text, value);
                this.$emit('input', value);
                this.$emit('change', value);
                this.onClosePicker();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .MySelectPicker {
        &__multiple {
            padding: 0 6px;
            &Option {
                padding: 10px;
                //border-bottom: 1px solid #ECEEF1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                &-text {
                    flex: 1;
                }
                &:last-child {
                    border-bottom: none;
                }
                &.active {
                    color: #206AFC;
                    .MySelectPicker__multipleOption-icon {
                        color: #4AD07E;
                    }
                }
            }
        }
    }
    .van-field ::v-deep .van-cell__right-icon {
        line-height: 20px;
        height: 20px;
    }
</style>
